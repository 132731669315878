import React from 'react';
import classes from '../ListManagement.module.css';

const PublicLists = (props) => (
    <table className='table is-striped'>
        <thead>
        <tr>
            <th>List Source</th>
            <th>List Name</th>
            <th>Latest update version</th>
            <th>Upload previous version</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>OFAC SDN</td>
            <td>{props.lists}</td>
            <td>{props.dates}</td>
            <td onClick={props.openModal}>
                <a href='# '>Available</a>
            </td>
        </tr>
        <tr className={classes.NotAvailable}>
            <td>OFAC CONSOLIDATED</td>
            <td>#</td>
            <td>#</td>
            <td>Not Available</td>
        </tr>
        <tr className={classes.NotAvailable}>
            <td>EUROPEAN UNION</td>
            <td>#</td>
            <td>#</td>
            <td>Not Available</td>
        </tr>
        <tr className={classes.NotAvailable}>
            <td>UNITED NATIONS</td>
            <td>#</td>
            <td>#</td>
            <td>Not Available</td>
        </tr>
        </tbody>
    </table>
);

export default PublicLists;