import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import classes from './Table.module.css';
import { faArrowCircleDown, faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default ({ columns, data }) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 5,
        sortBy: [{ id: 'Scenario generation date', desc: true }],
      },

    },
    useSortBy,
    usePagination,
    
  );

  const tableProps = {
    ...getTableProps(),
    className: 'table is-striped is-hoverable',
  };

  const btnClasses = ['button', 'is-small', classes.PageBtn].join(' ');

  return (
    <div>
      {
        <table {...tableProps}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  let tempProps = {
                    ...column.getHeaderProps(column.getSortByToggleProps()),
                  };

                  return (
                    <th {...tempProps}>
                      {column.render('Header')}
                      {' '}
                      {/* Without condition, sort icon is shown in each column, no matter active or not */}
                      {column.Header === 'Scenario generation date' ||
                      column.Header === 'Scenario case name' ||
                      column.Header === 'Result filename' ? (
                        <span>{column.isSorted ? 
                                column.isSortedDesc ? 
                                    <FontAwesomeIcon icon={faArrowCircleDown}/> : 
                                    <FontAwesomeIcon icon={faArrowCircleUp}/>
                                : ''
                                }
                        </span>
                      ) : null}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.value}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      }
      <div className={['pagination', classes.PaginationStyle]}>
        <button
          className={btnClasses}
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button
          className={btnClasses}
          onClick={() => previousPage()}
          disabled={!canPreviousPage}>
          {'<'}
        </button>
        <button
          className={btnClasses}
          onClick={() => nextPage()}
          disabled={!canNextPage}>
          {'>'}
        </button>
        <button
          className={btnClasses}
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}>
          {'>>'}
        </button>
        <span className={classes.Span}>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            className='input is-small'
            type='number'
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '100px' }}/>
        </span>{' '}
        <div className='select is-small'>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}>
            {[5, 10, 20, 30, 40].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};
