import React, { useState } from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import Pool from '../../../auth/UserPool';
import classes from './Upload.module.css';
import Notification from '../../../utility/Notification';

export default (props) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [warning, setWarning] = useState(false);
  const [reportIds, setReportIds] = useState([])
  const [successMsg, setSuccessMsg] = useState(null)

  const resetUploadNotification = () => {
    if (success)
      setSuccess(false);
    else if (warning)
      setWarning(false);
    else if (failed)
      setFailed(false);
  }

  const fileUploadHandler = async () => {
    resetUploadNotification();
    if (props.selectedFile) {
      setLoading(true);
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((error, session) => {
          if (!error) {
            const data = new FormData();
            data.append('file', props.selectedFile);
            data.append('filename', props.selectedFile.name);
            data.append('testId', props.selectedTest);
            axios
              .post(
                'https://adc42u61bg.execute-api.eu-central-1.amazonaws.com/prod/upload',
                data, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  // 'Access-Control-Allow-Origin': '*',
                  'Authorization': session.idToken.jwtToken
                }
              })
              .then((response) => {
                setLoading(false);
                if (response.status === 200 && response.data.report_ids_message === 'Report for the selected test is already generated!') {
                  setSuccess(true);
                  setSuccessMsg(response.data.report_ids_message);
                  setReportIds(response.data.report_ids);

                }

                else if (response.status === 200) {
                  setSuccess(true);
                  setSuccessMsg(response.data.report_ids_message);
                  setReportIds(response.data.report_ids);
                }
                else {
                  setFailed(true);
                }
              })
              .catch((error) => {
                console.log('Error: ' + error);
                setLoading(false);
                resetUploadNotification();
                setFailed(true);
              }
              );
          }
        })
      }
    } else {
      setWarning(true);
    }
  };

  const hideNotification = (id) => {
    const choiceDiv = document.getElementById(id);
    choiceDiv.style.display = 'none';
    resetUploadNotification();
  }

  const chartHandler = (event, report_id) => {
    const el = event.target;
    'false' !== getComputedStyle(el)
      .getPropertyValue('--chart-interactive').trim()
      && (t => el.parentNode.insertBefore(
        Object.assign(t, {
          type: 'application/javascript',
          src: 'https://datoris.com/embed/chart.js', id: report_id
        }), el) +
        el.parentNode.removeChild(el))(document.createElement('script'))
  }

  const loadDatorisImg = (event) => {
    const el = event.target;
    return (t => el.parentNode.replaceChild(Object.assign(t, {
      type: 'application/javascript',
      src: 'https://datoris.com/embed/dashboard_export.js'
    }), el))(document.createElement('script'));
  }


  return (
    <div className={classes.Section}>
      <button onClick={fileUploadHandler} className={['button', classes.Btn].join(' ')}>Upload</button>
      {loading ? (
        <Loader type='ThreeDots' color='#4FE894' />
      ) : null}
      {success ? (
        <Notification
          id='success-notification'
          className={['notification', classes.Section, classes.SectionSuccess].join(' ')}
          handler={hideNotification}
          msg={successMsg} />
      ) : null}
      {failed ? (
        <Notification
          id='fail-notification'
          className={['notification', 'is-danger', classes.Section].join(' ')}
          handler={hideNotification}
          msg='Upload failed!' />
      ) : null}
      {warning ? (
        <Notification
          id='warning-notification'
          className={['notification', 'is-warning', classes.Section].join(' ')}
          handler={hideNotification}
          msg='Please check your input, and try again!' />
      ) : null}
      {(Object.keys(reportIds).length > 0) ?
        (<div className={classes.ReportSection}>
          <img
            alt='Datoris logo'
            src='https://datoris.com/img/d.gif'
            onLoad={(e) => loadDatorisImg(e)} />
          <div className='datoris-row'>
            <h1>Sanctions Performance Assessment Report</h1>
          </div>
          <div className={classes.ChartDiv}>
            {/* <style>
            #datoris-1755 .chart-container--overview__circle { height: auto !important; width: auto !important; }
          </style> */}
            <p className={classes.PHeader}>Generation date:</p>
            <img
              src='https://datoris.com/img/d.gif'
              className='datoris'
              alt='Generation date'
              onLoad={(e) => chartHandler(e, reportIds.generation_date)} />
          </div>
          <div className='datoris-row'>
            <h2>General introduction and legal notices</h2>
          </div>
          <div className='datoris-row'>
            <h3>Tortor aliquam nulla facilisi cras</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <h3>At auctor urna</h3>
            <p>Ut porttitor leo a diam sollicitudin tempor. Quam adipiscing vitae proin sagittis nisl rhoncus mattis. Non odio euismod lacinia at quis. Sem et tortor consequat id porta nibh venenatis cras sed. Faucibus et molestie ac feugiat sed lectus vestibulum. Morbi non arcu risus quis varius quam quisque. Rhoncus dolor purus non enim praesent elementum facilisis leo. Sit amet cursus sit amet dictum sit amet justo donec. Quam quisque id diam vel. Ut venenatis tellus in metus vulputate eu scelerisque. Molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Enim facilisis gravida neque convallis a cras semper.</p>
          </div>
          <div className='datoris-row'>
            <h2>Executive Results</h2>
          </div>
          <div className='columns is-mobile is-centered is-vcentered'>
            <div className='column'>
              <img
                src='https://datoris.com/img/d.gif'
                alt='Total performance'
                onLoad={(e) => chartHandler(e, reportIds.total_performance)} />
            </div>
            <div className='column'>
              <img
                src='https://datoris.com/img/d.gif'
                alt='Names generated'
                onLoad={(e) => chartHandler(e, reportIds.names_generated)} />
            </div>
            <div className='column'>
              <img
                src='https://datoris.com/img/d.gif'
                alt='Names skipped'
                onLoad={(e) => chartHandler(e, reportIds.names_skipped)} />
            </div>
            <div className='column'>
              <img
                src='https://datoris.com/img/d.gif'
                alt='Names caught'
                onLoad={(e) => chartHandler(e, reportIds.names_caught)} />
            </div>
          </div>
          <div className='datoris-row'>
            <h2>General statistics</h2>
          </div>
          <div className='datoris-row'>
            <img
              src='https://datoris.com/img/d.gif'
              className='datoris'
              alt='General statistics'
              onLoad={(e) => chartHandler(e, reportIds.general_statistics)} />
          </div>
          <div className='datoris-row'>
            <h2>Statistics by group</h2>
          </div>
          <div className='datoris-row'>
            <img
              src='https://datoris.com/img/d.gif'
              className='datoris'
              alt='Statistics by group'
              onLoad={(e) => chartHandler(e, reportIds.statistics_by_group)} />
          </div>
          <div className='datoris-row'>
            <h2>Statistics per list</h2>
          </div>
          <div className='datoris-row'>
            <img
              src='https://datoris.com/img/d.gif'
              className='datoris'
              alt='Statistics per list'
              onLoad={(e) => chartHandler(e, reportIds.statistics_per_list)} />
          </div>
        </div>) : null}
    </div>
  );
}
