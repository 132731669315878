import React, { useState, useEffect, useRef } from 'react';
import classes from './TestScenarios.module.css';

const SettingsModal = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState('');
  let isRendered = useRef(false);

  const showModal = (id) => {
    setModalIsOpen('is-active');
  };

  useEffect(() => {
    isRendered.current = true;
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setModalIsOpen('');
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      isRendered.current = false;
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <div>
      <button
        onClick={() => showModal(props.id)}
        className={['button', classes.Btn].join(' ')}
      >
        Settings
      </button>

      <div className={['modal', modalIsOpen, classes.AnimateIn].join(' ')}>
        <div
          className={['modal-background', classes.BackgroundModal].join(' ')}
        />
        <div className='modal-card'>
          <header className='modal-card-head'>
            <p className='modal-card-title'>Test Information</p>
            <button
              onClick={() => setModalIsOpen(false)}
              className='delete'
              aria-label='close'
            />
          </header>
          <section className='modal-card-body'>
            <table className='table is-striped'>
              <tbody>
                <tr>
                  <td>
                    <b>Test Creation Parameters:</b>
                  </td>
                  <td>
                    <table className='table is-striped'>
                      <tbody>
                        <tr>
                          <td>
                            <b>Generation preference: </b>
                            <p>{props.generation_preference.join(', ')}</p>
                          </td>
                          <td>
                            <b>Generation rules: </b>
                            <p> {props.generation_rules.join(', ')}</p>
                          </td>
                          <td>
                            <b>Format: </b>
                            <p>{props.format}</p>
                          </td>
                          <td>
                            <b>Sample size: </b>
                            <p>{props.sample_size}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Sanctions list & issuance date:</b>
                  </td>
                  <td>
                    <span> {props.sanctions_list} </span>
                    <br />
                    <span>{props.sanctions_list_date}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Test file name:</b>
                  </td>
                  <td>{props.scenario_name}</td>
                </tr>
                <tr>
                  <td>
                    <b>Test creation date:</b>
                  </td>
                  <td>{props.test_creation_date}</td>
                </tr>
                <tr>
                  <td>
                    <b>Test result name:</b>
                  </td>
                  <td>{props.result_filename}</td>
                </tr>
              </tbody>
            </table>{' '}
          </section>
          <footer className='modal-card-foot'>
            <button
              onClick={() => setModalIsOpen(false)}
              style={{ textAlign: 'center' }}
              className={['button is-danger', classes.CloseModal].join(' ')}
            >
              Close
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
