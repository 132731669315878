import React, { useState, useEffect, useRef } from 'react';
import Pool from '../../auth/UserPool';
import classes from './TestGeneration.module.css';
import Notification from '../../utility/Notification';
import Loader from 'react-loader-spinner';
import ReactTooltip from 'react-tooltip';
import SwiftCheckbox from './SwiftCheckbox/SwiftCheckbox';

export default () => {
  // Checkboxes manipulation
  const [checks, setChecks] = useState({
    individuals: false,
    entity: false,
    vessel: false,
    bic: false,
    exact: true,
    shuffle: false,
    elimination: false,
    arabic: false
  })

  // Metadata for Lambda
  const [testUrl, setTestUrl] = useState(null);
  const [testCaseName, setTestCaseName] = useState(null);
  const [latestSanctionListUrl, setLatestSanctionListUrl] = useState(null);
  const [latestSanctionListDate, setLatestSanctionListDate] = useState(null);
  const [latestSanctionListName, setLatestSanctionListName] = useState(null);
  const [scenarioName, setScenarioName] = useState('');

  // Notifications
  const [warning, setWarning] = useState(null);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  let isRendered = useRef(false);

  useEffect(() => {
    isRendered.current = true;
    const user = Pool.getCurrentUser();
    const url =
      'https://8ifs0l8c5d.execute-api.eu-central-1.amazonaws.com/prod/list-data';

    if (user) {
      user.getSession((error, session) => {
        if (!error) {
          const fetchData = async () => {
            let response = await fetch(url, {
              headers: { 
                'Authorization': session.idToken.jwtToken,
              },
              });

            let data = null;
            if (isRendered.current) {
              data = await response.json(); 
              setLatestSanctionListUrl(data[2][0]);
              setLatestSanctionListDate(data[1][0]);
              setLatestSanctionListName(data[0][0]);
            }
          };
          fetchData()
            .then()
            .catch((error) => console.log);
        }
      });
    }
    return function cleanup() {
      isRendered.current = false;
    };
  });

  
  

  // Collect user choices from checkboxes
  const HandleTestGenerationData = async (e) => {
    resetNotification();
    e.preventDefault();
    const namePreferences = [];
    let namePreferenceData = document.querySelectorAll(
      'input[name=selectType]:checked'
    );

    for (let i = 0; i < namePreferenceData.length; i++) {
      namePreferences.push(namePreferenceData[i].value);
    }

    const generationRules = [];
    let generationRulesData = document.querySelectorAll(
      'input[name=generationRules]:checked'
    );

    for (let i = 0; i < generationRulesData.length; i++) {
      // Ignore disabled fields
      if (!generationRulesData[i].disabled) {
      generationRules.push(generationRulesData[i].value);
      }
    }
    let sampleSize = document.getElementById('test-select');
    let sampleSizeValue = sampleSize.options[sampleSize.selectedIndex].text;

    const format = 'SWIFT MT103';

    const generateMetaData = {
      generation_preference: namePreferences,
      generation_rules: generationRules,
      format: format,
      sample_size: parseInt(sampleSizeValue),
      latest_sanction_list_url: latestSanctionListUrl,
      scenario_name: scenarioName,
      latest_sanction_list_date: latestSanctionListDate,
      latest_sanction_list_name: latestSanctionListName,
    };

    // Validate if user has chosen all values
    if (
      namePreferences.length >= 1 &&
      generationRules.length >= 1 &&
      sampleSize.options[sampleSize.selectedIndex].value &&
      scenarioName.length > 1
    ) {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((error, session) => {
          if (!error) {
            const token = session.idToken.jwtToken;
            setLoading(true);
            // Send data to lambda for processing
            fetch(
              'https://ko6xuoonuh.execute-api.eu-central-1.amazonaws.com/prod/send-data',
              {
                method: 'post',
                body: JSON.stringify(generateMetaData),
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                  Authorization: token,
                },
              }
            )
              // Set returned data to test url and test name
              .then((data) => data.json())
              .then((json) => {
                setTestUrl(json.body.download_url);
                setTestCaseName(json.body.test_case_name);
                setLoading(false);
                setSuccess(true);
                resetForm();
              })

              .catch((error) => {
                console.log('My error', error);
                setFailed(true);
                setLoading(false);
                setSuccess(false);
              });
          }
        });
      }
    } else {
      setWarning(true);
    }
  };

  const hideNotification = (id) => {
    const choiceDiv = document.getElementById(id);
    choiceDiv.style.display = 'none';
    if (warning) {
      setWarning(false);
    } else if (failed) {
      setFailed(false);
    } else if (success) {
      setSuccess(false);
    }
  };

  const resetNotification = () => {
    if (warning) {
      setWarning(false);
    } else if (failed) {
      setFailed(false);
    } else if (success) {
      setSuccess(false);
    }
  };

  const scenarioNameHandler = (event) => {
    if (event.target.value) {
      setScenarioName(event.target.value);
    }
  };

  const testCaseUrl = (
    <div>
      {
        <a href={testUrl} target='_blank' rel='noopener noreferrer' download>
          Click to download file: {testCaseName}
        </a>
      }
    </div>
  );

  const resetForm = () => {
    document.getElementById('form').reset();
    setScenarioName('');
    setChecks((prev) => ({
      ...prev,
      exact: true,
      individuals: false,
      entity: false,
      vessel: false,
      bic: false,
      shuffle: false,
      elimination: false,
      arabic: false

    }))
  };

  return (
    <div className='columns features'>
      <div className='column is-12'>
        <div className='card is-shady'>
          <div className='card-content'>
            <div className='content'>
              <div className={classes.Headline}>
                <h2>Generate scenarios</h2>
                <p>Generate and download scenarios</p>
              </div>
              <div>
                <form id='form'>
                  <div className={[classes.Scenario, 'field'].join(' ')}>
                    <label className='label'>Scenario name:</label>
                    <div className='control'>
                      <input 
                        className={[
                          'input',
                          'is-primary',
                          classes.InputGenerate,
                        ].join(' ')} 
                        type='text' 
                        placeholder='Scenario name'
                        defaultValue={scenarioName}
                        onChange={scenarioNameHandler}/>
                    </div>
                  </div>
                  <div className={[classes.Scenario].join(' ')}>
                      <label className='label'>
                        Select names preferences:
                      </label>
                      <div className='name-preferance'>
                        <input
                          type='checkbox'
                          name='selectType'
                          value='Individual'
                          checked={checks.individuals}
                          onChange={() => setChecks((prev) => ({ ...prev, individuals: !prev.individuals}))}/>
                          
                        <label htmlFor='individual' className='checkbox'>
                          Individual
                        </label>
                        <br />
                        <input
                          type='checkbox'
                          name='selectType'
                          value='Entity'
                          checked={checks.entity}
                          onChange={() => setChecks((prev) => ({ ...prev, entity: !prev.entity}))}/>
                        <label htmlFor='entities' className='checkbox'>
                          Entity
                        </label>
                        <br/>
                        <input
                          type='checkbox'
                          name='selectType'
                          value='Vessel'
                          checked={checks.vessel}
                          onChange={() => setChecks((prev) => ({ ...prev, vessel: !prev.vessel}))} />
                        <label htmlFor='vessels' className='checkbox'>
                          Vessel
                        </label>
                        <br/>
                        <input
                          type='checkbox'
                          name='selectType'
                          value='BIC'
                          checked={checks.bic}
                          onChange={() => setChecks((prev) => ({ ...prev, bic: !prev.bic}))}/>
                        <label htmlFor='bic' className='checkbox'>
                          BIC
                        </label>
                        <br/>
                      </div>
                    </div>
                    <div className={[classes.Scenario].join(' ')}>
                      <div>
                        <label className='label'>
                          Select generation rules:
                        </label>
                        <div className='generation-rules'>
                          <div className='columns'>
                            <div className='column'>
                              <input
                                type='checkbox'
                                name='generationRules'
                                value='exact match'
                                defaultChecked={checks.exact}
                                onChange={() => setChecks((prev) => ({ ...prev, exact: !prev.exact}))}/>
                                <label
                                htmlFor='exact match'
                                className='checkbox'
                                id='exact match'>
                                Exact Match
                              </label>
                            </div>
                            <div className='column'>
                              <ul className={classes.Ul}>
                                <li>
                                  <input
                                    type='checkbox'
                                    name='generationRules'
                                    value='shuffle groups'
                                    checked={checks.shuffle}
                                    disabled={checks.bic}
                                    onChange={() => setChecks((prev) => ({ ...prev, shuffle: !prev.shuffle}))}/>
                                    <label
                                    htmlFor='shuffle groups'
                                    className='checkbox'
                                    id='shuffle groups'>
                                    Shuffle Group
                                  </label>
                                </li>
                                {checks.shuffle ? (
                                  <ul>
                                    <li className={classes.List}>
                                      <input
                                        type='checkbox'
                                        defaultChecked={checks.shuffle}
                                        disabled/>
                                        First Name / Last Name 'No middle name'
                                    </li>
                                    <li className={classes.List}>
                                      <input type='checkbox' disabled/>
                                      Last Name / First Name 'No middle name'
                                    </li>
                                    <li className={classes.List}>
                                      <input type='checkbox' disabled/>
                                      Last Name / First Name / Second Name
                                    </li>
                                    <li className={classes.List}>
                                      <input type='checkbox' disabled/>
                                      Second Name / First Name / Last name
                                    </li>
                                  </ul>
                                ) : null}
                              </ul>
                            </div>
                            <div className={['column', classes.Elimination].join(' ')}>
                              <ul className={classes.Ul}>
                                <li>
                                  <input
                                    type='checkbox'
                                    name='generationRules'
                                    value='elimination tests'
                                    checked={checks.elimination}
                                    disabled={checks.bic}
                                    onChange={() => setChecks((prev) => ({ ...prev, elimination: !prev.elimination}))}/>
                                    <label
                                    htmlFor='elimination tests'
                                    className='checkbox'
                                    id='elimination'>
                                      Elimination Group
                                  </label>
                                </li>
                                {checks.elimination ? (
                                  <ul>
                                    <li className={classes.List}>
                                      <input
                                        type='checkbox'
                                        defaultChecked={checks.elimination}
                                        disabled/>
                                        Remove all spaces
                                    </li>
                                    <li className={classes.List}>
                                      <input type='checkbox' disabled/>
                                      Remove random character from first name
                                    </li>
                                  </ul>
                                ) : null}
                              </ul>
                            </div>{' '}
                            <div className='column'>
                              <ul className={classes.Ul}>
                                <li>
                                <input
                                type='checkbox'
                                name='generationRules'
                                value='arabic groups'
                                id='arabic groups'
                                checked={checks.arabic}
                                disabled={checks.entity || checks.vessel || checks.bic}
                                onChange={() => setChecks((prev) => ({ ...prev, arabic: !prev.arabic}))}/>
                                <label
                                htmlFor='arabic groups'
                                id='arabic groups'
                                data-tip
                                data-for='arabic-group'>
                                Arabic Group
                              </label>{' '}
                              <ReactTooltip
                                id='arabic-group'
                                place='top'
                                effect='solid'>
                                Mohamed variations, Ahmed variations, company
                                type variations
                              </ReactTooltip>
                                </li>
                                {checks.arabic ? (
                                  <ul>
                                    <li className={classes.List}>
                                      <input
                                        type='checkbox'
                                        defaultChecked={checks.arabic}
                                        disabled/>
                                        Mohamad derivation group
                                    </li>
                                    <li className={classes.List}>
                                      <input type='checkbox' disabled/>
                                      Abdul derivation group
                                    </li>
                                    <li className={classes.List}>
                                      <input type='checkbox' disabled/>
                                      Ahmed derivation group
                                    </li>
                                  </ul>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                            <div className='columns'>
                              <div className='column'>
                                <div className={classes.Addition}>
                                  <input
                                    type='checkbox'
                                    name='generationRules'
                                    value='addition group'
                                    disabled/>
                                  <label
                                    htmlFor='addition group'
                                    className='checkbox'
                                    id='addition group'
                                    data-tip
                                    data-for='addition-group'>
                                    Addition Group
                                  </label>{' '}
                                  <ReactTooltip
                                    id='addition-group'
                                    place='top'
                                    effect='solid'>
                                    Add titles, add company type, insert letters
                                    at random <br />
                                    insert space between characters
                                  </ReactTooltip>
                                </div>
                              </div>
                              <div className='column'>
                                <div className={classes.Substitution}>
                                  <input
                                    type='checkbox'
                                    name='generationRules'
                                    value='substitution group'
                                    disabled/>
                                  <label
                                    htmlFor='substitution group'
                                    className='checkbox'
                                    id='substitution group'
                                    data-tip
                                    data-for='substitution-group'>
                                    Substitution Group
                                  </label>
                                  <ReactTooltip
                                    id='substitution-group'
                                    place='top'
                                    effect='solid'>
                                    Double letters with one letter, sub space with
                                    special character,
                                    <br />
                                    sub random vowel
                                  </ReactTooltip>
                                </div>
                              </div>
                              <div className='column'>
                                <div>
                                  <input
                                    type='checkbox'
                                    name='generationRules'
                                    value='phonetics'
                                    disabled/>
                                  <label
                                    htmlFor='phonetics'
                                    className='checkbox'
                                    id='phonetics'
                                    data-tip
                                    data-for='phonetics'>
                                    Phonetics
                                  </label>
                                  <ReactTooltip
                                    id='phonetics'
                                    place='top'
                                    effect='solid'>
                                    Stefan, Steven, Stephen | Geroge, Jorje, Djodje
                                  </ReactTooltip>
                                  <br />
                                </div>
                              </div>
                              <div className='column'>
                                <div>
                                  <input
                                    type='checkbox'
                                    name='generationRules'
                                    value='misspelling'
                                    disabled
                                    />
                                  <label
                                    htmlFor='misspelling'
                                    className='checkbox'
                                    id='misspelling'
                                    data-tip
                                    data-for='misspelling'>
                                    Misspelling
                                  </label>{' '}
                                  <ReactTooltip
                                    id='misspelling'
                                    place='top'
                                    effect='solid'>
                                    Mohd, Ahmd, Suleyman
                                  </ReactTooltip>
                                </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={classes.Scenario}>
                      <label className='label'>
                        Choose your list:
                      </label>
                      <input
                        type='checkbox'
                        name='ofacSDN'
                        value='ofacSDN'
                        defaultChecked
                        disabled
                        />
                      <label
                        htmlFor='ofacSDN'>
                          OFAC SDN
                      </label>
                      <br/>
                      <input
                        type='checkbox'
                        name='ofacConsolidated'
                        value='ofacConsolidated'
                        disabled/>
                      <label
                        htmlFor='ofacConsolidated'>
                          OFAC CONSOLIDATED
                      </label>
                      <br/>
                      <input
                        type='checkbox'
                        name='eu'
                        value='eu'
                        disabled/>
                      <label
                        htmlFor='eu'>
                          EUROPEAN UNION
                      </label>
                      <br/>
                      <input
                        type='checkbox'
                        name='un'
                        value='un'
                        disabled/>
                      <label
                        htmlFor='un'>
                          UNITED NATIONS
                      </label>
                    </div>


                    <div className={classes.Scenario}>
                        <label htmlFor='test-select' className='label'>Choose sample size:</label>
                        <div className={['select', classes.Selection].join(' ')}>
                          <select
                            name='test'
                            id='test-select'
                            className={classes.Selection}
                            required>
                            <option value=''>--Sample size--</option>
                            <option value='10'>10</option>
                            <option value='20'>20</option>
                            <option value='30'>30</option>
                            <option value='40'>40</option>
                            <option value='50'>50</option>
                          </select>
                        </div>
                    </div>
                    <div className={classes.Scenario}>
                      <div>
                        <SwiftCheckbox />
                      </div>
                    </div>
                    <div className='buttons'>
                      <button
                        type='submit'
                        className={['button', classes.Btn, classes.Generate].join(' ')}
                        onClick={HandleTestGenerationData}>
                        Generate test scenario
                      </button>
                    </div>
                </form>
              </div>
              {loading ? (
                <Loader className={classes.Loader} color='#4FE894' type='ThreeDots'/>
              ) : null}
              {warning ? (
                <Notification
                  id='warning-notification'
                  className={[
                    'notification',
                    'is-warning',
                    classes.Section,
                  ].join(' ')}
                  handler={hideNotification}
                  msg='Please enter all parameters!'
                />
              ) : null}
              {failed ? (
                <Notification
                  id='fail-notification'
                  className={[
                    'notification',
                    'is-danger',
                    classes.Section,
                  ].join(' ')}
                  handler={hideNotification}
                  msg='Test Generation failed!'
                />
              ) : null}
              {success ? (
                <Notification
                  id='success-notification'
                  className={[
                    'notification',
                    'is-success',
                    classes.Section,
                    classes.SectionSuccess,
                  ].join(' ')}
                  handler={hideNotification}
                  msg={testCaseUrl}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
