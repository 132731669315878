import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Pool from '../../auth/UserPool';
import Table from '../TestScenarioList/Table/Table';
import classes from './TestScenarios.module.css';
import SettingsModal from './SettingsModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default () => {
  const [testData, setTestData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(['modal']);
  const [testId, setTestId] = useState(-1);
  let isRendered = useRef(false);
  const FILE_TYPES = ['scenario', 'result'];
  const [sortOptions] = useState({ id: 'row.test_creation_date', desc: true });
  const [nameSearch, setNameSearch] = useState('');
  const [dateSearch, setDateSearch] = useState('');
  const [searchBar, setSearchBar] = useState(false);
  const [icon, setIcon] = useState('');

  useEffect(() => {
    const user = Pool.getCurrentUser();
    isRendered.current = true;
    if (user) {
      user.getSession((error, session) => {
        if (!error) {
          axios
            .get(
              'https://fo4a5q7ied.execute-api.eu-central-1.amazonaws.com/prod',
              {
                'headers': {
                  'Authorization': session.idToken.jwtToken,
                },
              }
            )
            .then((res) => {
              if (isRendered.current) {
                setTestData(res.data);
              }
            })
            .catch((error) => {
              if (typeof error !== 'undefined') console.log(error);
            });
        }
      });
    }

    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        console.log('Close');
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      isRendered.current = false;
      window.removeEventListener('keydown', handleEsc);
    };
  }, [setTestData]);

  const deleteTest = (id) => {
    setTestData(testData.filter((test) => test.id !== id));
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession((err, session) => {
        if (!err) {
          axios
            .delete(
              'https://fo4a5q7ied.execute-api.eu-central-1.amazonaws.com/prod',
              {
                'headers': {
                  'Authorization': session.idToken.jwtToken,
                  'TestId': id,
                },
              }
            )
            .then()
            .catch((error) => {
              if (typeof error !== 'undefined') console.log(error);
            });
          setDeleteModal(
            deleteModal.filter((className) => className !== 'is-active')
          );
          setTestId(-1);
        }
      });
    }
  };

  const togleDeleteModal = (id) => {
    if (deleteModal.indexOf('is-active') > -1) {
      setDeleteModal(
        deleteModal.filter((className) => className !== 'is-active')
      );
      setTestId(-1);
    } else {
      setDeleteModal([...deleteModal, 'is-active', classes.AnimateIn]);
      setTestId(id);
    }
  };

  const downloadFile = (e, id, filename, file_type) => {
    // prevent page refresh
    e.preventDefault();
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession((err, session) => {
        if (!err) {
          axios
            .post(
              'https://ae5cls4t64.execute-api.eu-central-1.amazonaws.com/prod/',
              {
                'filename': filename,
                'file_type': file_type,
                'id': id,
              },
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': session.idToken.jwtToken,
                },
              }
            )
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', filename);
              document.body.appendChild(link);
              link.click();
            })
            .catch((error) => {
              if (typeof error !== 'undefined') console.log(error);
            });
        }
      });
    }
  };

  const handleSearch = () => {
    setDateSearch(document.getElementById('date').value);
    setNameSearch(document.getElementById('name').value);
  };

  const resetSearch = () => {
    setNameSearch('');
    setDateSearch('');
    document.getElementById('date').value = '';
    document.getElementById('name').value = '';
  };

  let filteredData = testData.filter((el) => {
    if (dateSearch && nameSearch) {
      return (
        el.test_creation_date.includes(dateSearch) &&
        el.scenario_name.toLowerCase().includes(nameSearch.toLowerCase())
      );
    } else if (dateSearch) {
      return el.test_creation_date.includes(dateSearch);
    } else {
      return el.scenario_name.toLowerCase().includes(nameSearch.toLowerCase());
    }
  });

  const columns = [
    {
      Header: 'Scenario case name',
      accessor: 'scenario_name'
    },
    {
      Header: 'Scenario generation date',
      accessor: 'test_creation_date',
      isSorted: true,
      isSortedDesc: true,
    },
    {
      Header: 'Download scenario case',
      accessor: (row) => (
        <button
          className={['button', classes.Btn].join(' ')}
          onClick={(e) =>
            downloadFile(e, row.id, row.scenario_name, FILE_TYPES[0])}>
          Download test file
        </button>
      ),
      disableSortBy: true,
    },

    {
      Header: 'Settings',
      accessor: (row) => (
        <SettingsModal
          key={row.id}
          format={row.format}
          id={row.id}
          sample_size={row.sample_size}
          sanctions_list={row.sanctions_list}
          sanctions_list_date={row.sanctions_list_date}
          scenario_name={row.scenario_name}
          test_creation_date={row.test_creation_date}
          result_filename={row.result_filename}
          generation_preference={row.generation_preference}
          generation_rules={row.generation_rules} />
      ),
      disableSortBy: true,
    },

    {
      Header: 'Result filename',
      accessor: (row) => (
        <a
          href='/'
          onClick={(e) => downloadFile(e, row.id, row.result_filename, FILE_TYPES[1])} >
          {row.result_filename}
        </a>
      ),
    },

    {
      Header: 'Action',
      accessor: (test) => (
        <button
          className='button is-danger'
          id='download'
          onClick={() => togleDeleteModal(test.id)}>
          Delete
        </button>
      ),
      disableSortBy: true,
    },
  ];

  return (
    <section className='container'>
      <div className={deleteModal.join(' ')}>
        <div className='modal-background' onClick={() => togleDeleteModal()} />
        <div className='modal-card'>
          <header className='modal-card-head'>
            <p className='modal-card-title'>Delete confirmation</p>
            <button
              className='delete'
              aria-label='close'
              onClick={() => togleDeleteModal()}
            />
          </header>
          <section className='modal-card-body'>
            Are you sure you want to delete this case?
          </section>
          <footer className='modal-card-foot'>
            <button
              className='button is-success'
              onClick={() => deleteTest(testId)}
            >
              Confirm
            </button>
            <button className='button' onClick={() => togleDeleteModal()}>
              Cancel
            </button>
          </footer>
        </div>
      </div>
      <div className='columns features'>
        <div className='column is-12'>
          <div className='card is-shady'>
            <div className='card-content'>
              <div className='content'>
                <h3>Manage test scenarios</h3>
                <FontAwesomeIcon
                  className={icon ? classes.IconActive : classes.Icon}
                  icon={faSearch}
                  size='2x'
                  onClick={() => {
                    setSearchBar(!searchBar);
                    setIcon(!icon);
                  }} />
                <br />
                {searchBar ? (
                  <div
                    className={
                      icon ? classes.SearchAreaActive : classes.SearchArea}>
                    <div className='field is-centered'>
                      <div className='control'>
                        <input
                          className={[
                            'input is-success',
                            classes.SearchBar,
                          ].join(' ')}
                          type='text'
                          placeholder='Search by date...'
                          id='date'
                        />
                      </div>
                      <div className='control'>
                        <input
                          className={[
                            'input is-success',
                            classes.SearchBar,
                          ].join(' ')}
                          type='text'
                          placeholder='Search by name...'
                          id='name' />
                      </div>
                      <div className='field is-grouped'>
                        <div className='buttons is-left'>
                          <button
                            onClick={handleSearch}
                            className={[
                              'button',
                              classes.Btn,
                              classes.FilterBtn,
                            ].join(' ')}>
                            Search
                          </button>
                          <button
                            onClick={resetSearch}
                            className={[
                              'button',
                              'is-danger',
                              classes.ResetBtn,
                            ].join(' ')}>
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <hr />
                <div className='table-container'>
                  <Table
                    columns={columns}
                    data={filteredData}
                    sorted={sortOptions} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
