import React from 'react';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './PrepareUpload.module.css';

export default (props) => {
  return (
    <div>
      <label className={[classes.LabelLeft, classes.Label].join(' ')}>Select generated test</label>
      <label className={classes.Label}>Upload result file</label>
      <div className={classes.Inline}>
        <div className={['select','is-small', classes.Selection].join(' ')}>
          <select
            className={classes.Selection}
            onChange={(e)=>props.testSelectedHandler(e.target.value)} 
          >
              {props.dropdownData.map((el) => (
                <option key={el.id} value={el.id}>
                  {el.name}
                </option>
              ))}
          </select>
        </div>
        <div className={['file', 'has-name', 'is-small', classes.InlineSeparate].join(' ')}>
          <label className='file-label'>
            <input className='file-input' type='file' name='resume' onChange={props.fileSelectedHandler}/>
            <span className='file-cta'>
              <span className='file-icon'>
                <FontAwesomeIcon icon={faUpload} /> 
              </span>
              <span className='file-label'>
                Choose a file…
              </span>
            </span>
            {props.selectedFile ? (
              <span className='file-name'>
              {props.selectedFile.name}
              </span>
            ) : null }
          </label>
        </div>
      </div>
    </div>
  );
};
