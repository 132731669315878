import React from 'react';
import classes from '../ListManagement.module.css';

const ListModal = (props) => (
  <div className={['modal', props.modalIsOpen, classes.AnimateIn].join(' ')}>
    <div className={['modal-background', classes.BackgroundModal].join(' ')} />
    <div className='modal-card'>
      <header className='modal-card-head'>
        <p className='modal-card-title'>List names and issuance dates</p>
        <button
          onClick={props.CloseModal}
          className={['delete', classes.CloseModal].join(' ')}
          aria-label='close'
        />
      </header>
      <section className='modal-card-body'>
        <table className='table is-striped is-fullwidth' align='center'>
          <thead>
            <tr>
              <th>List Name</th>
              <th>List Issuance Date</th>
            </tr>
          </thead>
          <tbody>
            {props.lists.map((list, index) => (
              <tr key={index}>
                <td>{list}</td>
                <td>{props.dates[index]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
      <footer className='modal-card-foot'>
        <button
          onClick={props.CloseModal}
          className={['button is-danger', classes.CloseModal].join(' ')}
        >
          Close
        </button>
      </footer>
    </div>
  </div>
);

export default ListModal;
