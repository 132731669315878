import React, { createContext, useState } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Pool from './UserPool';
import { useIdleTimer } from 'react-idle-timer';

const AccountContext = createContext();

const Account = props => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userName, setUserName ] = useState("");

  const getSession = async () => {
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((error, session) => {
          if (error) {
            reject();
          } else {
            setIsAuthenticated(true);
            setUserName(user.username)
            resolve(session);
          }
        });
      } else {
        logout();
        reject();
      }
    });
  }
  
  const authenticate = async (Username, Password) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: data => {
          setIsAuthenticated(true);
          setUserName(user.name)
          resolve(data);
        },

        onFailure: error => {
          reject(error);
        },

        newPasswordRequired: data => {
          resolve(data);
        }
      });
    });
  
  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    }
    setIsAuthenticated(false);
    setUserName("")
  }

  useIdleTimer({
    timeout: 1000 * 60 * 10,
    onIdle: () => {
      if(Pool.getCurrentUser()) {
        return logout()
      }
    }
  })

  return (
    <AccountContext.Provider value={{
      authenticate,
      getSession,
      logout,
      isAuthenticated,
      userName
    }}>
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };