import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AccountContext } from '../auth/Accounts';
import LogIn from '../auth/LogIn';

export default ({ render, ...routeProps }) => {
  const { isAuthenticated } = useContext(AccountContext);
  return (
    <Route
      {...routeProps}
      render={() => (isAuthenticated ? 
        <Redirect to='/testscenarios' />  : <LogIn/>
        )
      }
    />
  );
};