import React from 'react';
import classes from '../ListManagement.module.css';

const PrivateLists = (props) => (
    <div>
        <h3 className={classes.ListSection}>Private Lists</h3>
        <table className='table is-striped'>
            <thead>
            <tr>
                <th>List Source</th>
                <th>List Name</th>
                <th>Latest update version</th>
            </tr>
            </thead>
            <tbody>
            <tr className={classes.NotAvailable}>
                <td>#</td>
                <td>#</td>
                <td>#</td>
            </tr>
            <tr className={classes.NotAvailable}>
                <td>#</td>
                <td>#</td>
                <td>#</td>
            </tr>
            <tr className={classes.NotAvailable}>
                <td>#</td>
                <td>#</td>
                <td>#</td>
            </tr>
            <tr className={classes.NotAvailable}>
                <td>#</td>
                <td>#</td>
                <td>#</td>
            </tr>
            </tbody>
        </table>
        <button
            id='upload-btn'
            className={['button', classes.Btn].join(' ')}>
            Upload private list
        </button>
    </div>

);

export default PrivateLists;