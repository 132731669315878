import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AccountContext } from '../auth/Accounts';

export default ({ component: Component, ...otherProps }) => {
  const { isAuthenticated } = useContext(AccountContext);
  return (
      <Route
          {...otherProps}
          render={props => (
            isAuthenticated
                ?
                <Component {...props} />
                :
                <Redirect to='/login'/> 
          )}
      />
  )

}

