import React, {Component, createRef} from 'react';
import Pool from '../../../auth/UserPool';
import Loader from 'react-loader-spinner';
import classes from './ListManagement.module.css';
import PublicLists from './PublicLists/PublicLists';
import ListModal from './ListModal/ListModal';
import PrivateLists from "./PrivateLists/PrivateLists";


export default class ListManagement extends Component {
    state = {
        loading: true,
        lists: [],
        dates: [],
        modalIsOpen: '',
    };
    isRendered = createRef(false);

    componentDidMount() {
        const user = Pool.getCurrentUser();
        const url =
            'https://8ifs0l8c5d.execute-api.eu-central-1.amazonaws.com/prod/list-data';
        this.isRendered.current = true;
        if (user) {
            user.getSession((error, session) => {
                if (!error) {
                    const fetchData = async () => {
                        let response = await fetch(url, {
                            'headers': {
                            'Authorization': session.idToken.jwtToken,
                            },
                        });
                        if (this.isRendered.current) {
                            let data = await response.json();
                            let lists = data[0];
                            let dates = data[1];
                            this.setState({
                                lists: lists,
                                dates: dates,
                                loading: false,
                            });
                        }
                    };
                    fetchData()
                        .then()
                        .catch((error) => {
                            if (typeof error !== 'undefined') console.log(error);
                        });
                }
            });
            window.addEventListener('keydown', this.handleEsc.bind(this));
        }
    }

    handleEsc = (event) => {
        if (event.keyCode === 27) {
            this.setState({modalIsOpen: ''});
        }
    };

    OpenModal = () => {
        this.setState({modalIsOpen: 'is-active'});
    };

    CloseModal = () => {
        this.setState({modalIsOpen: ''})
    }

    componentWillUnmount() {
        this.isRendered.current = false;
    }

    render() {
        const {lists, dates, loading, modalIsOpen} = this.state;

        return (
            <div>
                {loading ? (
                    <Loader type='ThreeDots' color='#4fe894' height='100' width='100'/>
                ) : (
                    <div>
                        <PublicLists dates={dates[0]} lists={lists[0]} openModal={this.OpenModal}/>
                        <ListModal CloseModal={this.CloseModal} lists={lists} dates={dates} modalIsOpen={modalIsOpen}/>
                        <PrivateLists />
                    </div>
                )}
                <div className={['notification', classes.NotificationStyle].join(' ')}>
                    New version of the OFAC list is generated once a week
                </div>
            </div>
        );
    }
}

