import React from 'react';



function SwiftCheckbox () 
 { return (
     <div>
  <div>
    <p>SWIFT MT103 format:</p>
    <input type='checkbox' name='format' value='format' defaultChecked disabled/>
    <label htmlFor='teaser' className='checkbox'>
      50 K
    </label>{' '}
    <input type='checkbox' name='teaser' value='teaser' disabled />
    <label htmlFor='teaser' className='teaser'>
      50F
    </label>{' '}
    <input type='checkbox' name='teaser' value='teaser' disabled />
    <label htmlFor='teaser' className='teaser'>
      59
    </label>{' '}
    <input type='checkbox' name='teaser' value='teaser' disabled />
    <label htmlFor='teaser' className='teaser'>
      59F
    </label>{' '}
    <input type='checkbox' name='teaser' value='teaser' disabled />
    <label htmlFor='teaser' className='teaser'>
      70
    </label>{' '}
    <input type='checkbox' name='teaser' value='teaser' disabled />
    <label htmlFor='teaser' className='teaser'>
      72
    </label>{' '}
  </div>
</div>
 )
};

export default SwiftCheckbox;

