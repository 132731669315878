import React, {Component} from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Footer from './components/Footer';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Account } from './components/auth/Accounts';
import AuthenticatedRoute from './components/utility/AuthenticatedRoute';
import PublicRoute from './components/utility/PublicRoute';
import TestScenarios from './components/containers/TestScenarioList/TestScenarios';
import SanctionsListManagement from './components/containers/SanctionsListsManagement/SanctionsListManagement';
import TestGeneration from './components/containers/TestGeneration/TestGeneration';
import ProcessData from './components/containers/ProcessData/ProcessData';
library.add(faEdit);

class App extends Component {
  render() {
    return (
      <Account>
        <div className="App">
          <Router>
            <div>
              <Navbar/>
              <Switch>
                <PublicRoute exact path="/login"/>
                <AuthenticatedRoute exact path="/" component={TestScenarios}/>
                <AuthenticatedRoute exact path="/testscenarios" component={TestScenarios}/>
                <AuthenticatedRoute exact path="/sanctionslist" component={SanctionsListManagement}/>
                <AuthenticatedRoute exact path="/generatetest" component={TestGeneration}/>
                <AuthenticatedRoute exact path="/processresults" component={ProcessData}/>
              </Switch>
              <Footer />
            </div>
          </Router>
        </div>
      </Account>
    );
  }
}

export default App;
