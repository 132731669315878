import React from 'react';
import ListManagement from './ListsManagement/ListsManagement';
import classes from './ListsManagement/ListManagement.module.css';

export default () => {
    return (
        <div className='columns features'>
        <div className='column is-12'>
          <div className='card is-shady'>
            <div className='card-content'>
              <div className='content'>
                <h2>Sanctions lists management</h2>
                <h3 className={classes.ListSection}>Public Lists</h3>
                <ListManagement />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
