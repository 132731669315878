import React, { useContext,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {AccountContext} from '../auth/Accounts';
import classes from './Navbar.module.css';
import { NavLink } from "react-router-dom";

export default () => {
  
  const { getSession, logout, isAuthenticated, userName} = useContext(AccountContext);
  const history = useHistory();
  const navbarClasses = ['navbar', classes.StickyNav];

  useEffect(()=>{
    getSession()
    .then()
    .catch(error=> {
      if(typeof error !== 'undefined') 
        console.log(error);
    })
  });

  const handleLogOut = async event => {
    event.preventDefault();
    try {
      logout();
      history.push('/login');
    } catch(error) {
      if(typeof error !== 'undefined') 
        console.log(error);
    }
  }
  
  return (
    <nav className={navbarClasses.join(' ')} role='navigation' aria-label='main navigation'>
      {/* <div className='navbar-brand'>
        <a href='/' className='navbar-item'>
          GRC logo
        </a>
      </div> */}

      <div id='navbarGRC' className='navbar-menu'>
          {(isAuthenticated) &&
        <div className='navbar-start'>
          <NavLink to='/testscenarios' className='navbar-item' activeClassName={classes.Active}>Scenarios Database</NavLink>
          <NavLink to='/sanctionslist' className='navbar-item' activeClassName={classes.Active}>List Management</NavLink>
          <NavLink to='/generatetest' className='navbar-item' activeClassName={classes.Active}>Scenario Generator</NavLink>
          <NavLink to='/processresults' className='navbar-item' activeClassName={classes.Active}>Dashboard & Reports</NavLink>
        </div>}
        <div className='navbar-end'>
          <div className='navbar-item'>
            {(isAuthenticated && userName) && (
              <p>
                Hello, {userName}
              </p>
            )}
            <div className='buttons'>
              {!isAuthenticated && (
                <div>
                  <a href='/login' className='button is-light'>
                    Log in
                  </a>
                </div>
              )}
              {isAuthenticated && (
                <a href='/login' onClick={handleLogOut} className='button is-light'>
                  Log out
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

