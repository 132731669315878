import React, { useEffect, useState, useRef } from 'react';
import PrepareUpload from './PrepareUpload/PrepareUpload';
import Upload from './Upload/Upload';
import axios from 'axios';
import Pool from '../../auth/UserPool';


export default () => {

  const [dropdownData, setDropdownData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedTest, setSelectedTest] = useState(-1);
  let isRendered = useRef(false);

  useEffect(() => {
    isRendered.current = true;
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession((error, session) => {
        if (!error) {
          axios
            .get(
              'https://adc42u61bg.execute-api.eu-central-1.amazonaws.com/prod/upload',
              {
                'headers': {
                  'Authorization': session.idToken.jwtToken,
                },
              }
            )
            .then((res) => {
              if (isRendered.current && res.data.length > 0) {
                setSelectedTest(res.data[0].id);
                setDropdownData(res.data);
              }
            })
            .catch((error) => {
              if (typeof error !== 'undefined')
                console.log(error);
            });
        }
      });
    }
    return () => {
      isRendered.current = false;
    }
  }, [setDropdownData]);

  const fileSelectedHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const testSelectedHandler = (test) => {
    setSelectedTest(test);
  }

  return (
    <div className='columns features'>
      <div className='column is-12'>
        <div className='card is-shady'>
          <div className='card-content'>
            <div className='content'>
              <h2>Analyze results</h2>
              <PrepareUpload
                testSelectedHandler={testSelectedHandler}
                fileSelectedHandler={fileSelectedHandler}
                selectedFile={selectedFile}
                dropdownData={dropdownData}
              />
              <Upload
                selectedFile={selectedFile}
                selectedTest={selectedTest}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}