import React, { useState, useContext } from 'react';
import FormErrors from "../FormErrors";
import { AccountContext } from './Accounts';
import { useHistory } from 'react-router-dom';

export default () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();

  const { authenticate } = useContext(AccountContext);

  const  handleSubmit  = event => {
    event.preventDefault();
    authenticate(username, password)
      .then(data => {
        history.push("/testscenarios");
      })
      .catch(error => {
        console.error("Failed to login!", error);
        setError(error);
      })
  };

  return (
    <section className="section auth">
        <div className="container">
          <h1>Log in</h1>
          <FormErrors formerrors={error} />

          <form onSubmit={handleSubmit}>
            <div className="field">
              <p className="control">
                <input 
                  className="input" 
                  type="text"
                  id="username"
                  aria-describedby="usernameHelp"
                  placeholder="Enter username or email"
                  value={username}
                  onChange={event => setUsername(event.target.value)}
                />
              </p>
            </div>
            <div className="field">
              <p className="control">
                <input 
                  className="input" 
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
            </div>
             {/* <div className="field">
              <p className="control">
                <a href="/forgotpassword">Forgot password?</a>
              </p>
            </div>  */}
            <div className="field">
              <p className="control">
                <button className="button is-success">
                  Login
                </button>
              </p>
            </div>
          </form>
        </div>
      </section>
  );
};