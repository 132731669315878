import React from "react";

function FormErrors(props) {
  if (
    props.formerrors && 
    ( props.formerrors.code === "InvalidParameterException" ||
      props.formerrors.code === "NotAuthorizedException")
  ) {
    return (
      <div className="error container help is-danger">
        <div className="row justify-content-center">
          Invalid username or password
        </div>
      </div>
    );
  } else if (props.apierrors) {
    return (
      <div className="error container help is-danger">
        <div className="row justify-content-center">{props.apierrors}</div>
      </div>
    );
  } else if (props.formerrors && props.formerrors.cognito) {
    return (
      <div className="error container help is-danger">
        <div className="row justify-content-center">
          {props.formerrors.cognito.message}
        </div>
      </div>
    );
  } else {
    return <div />;
  }
}

export default FormErrors;